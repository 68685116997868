<template>
<main
    class="main-content page-questions pt-0 bg-oct"
    role="main"
  >
      <div>
        <div style="padding-top: 20px" class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="disciplina"
                >Componente Curricular
                <i class="far fa-question-circle" style="color: #d2d2d2"/>
              </label>

              <input
                id="titulo"
                :value="prova.disciplinaText"
                type="text"
                class="form-control"
                :disabled="true"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="ano"
                >Ano <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="titulo"
                :value="prova.turmaAnoTitulo"
                type="text"
                class="form-control"
                :disabled="true"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="turma"
                >Turma <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
              <input
                id="titulo"
                :value="prova.turmaTitulo"
                type="text"
                class="form-control"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <div class="form-group" >
              <label for="instrucoes"
                >Instruções da Prova
                <i class="far fa-question-circle" style="color: #d2d2d2"
              /></label>
               <!-- <p>{{ prova.instrucoes }} </p>  -->
                <textarea
                id="instrucoes"
                :value="prova.instrucoes"
                rows="5"
                class="form-control"
                :disabled="true"
              />
            </div>
          </div>
        </div>
        <hr>
        <div>
          <h4>Questões</h4>
          <questao-list
            v-for="questao in prova.provaQuestoes"
            :key="questao.id"
            :questao-prop="questao"
            :ordemProva="prova.ordemQuestoes"
            :is-list-prova="true"
            :is-list-inserida-prova="true"
            :previewProva="true"
            :preview="false"
          />
        </div>
      </div>            
  </main>
</template>

 
<script>

import moment from "moment";
 import QuestaoList from "@/components/questoes/QuestaoList";

export default {
  components: {
    QuestaoList
  },
  props: {
    prova: { type: Object },
    assuntos: {},    
    anos: { },
    turmas: { },
    disciplinas: { },
    questoes : {}
  },
  filters:{     
      formatDate(value) {
        if (value) {
          return moment(String(value)).format('DD/MM/YYYY hh:mm')        
        }else{
          return '-'
        }
      }
  },
  created() {
      console.log(this.prova);
  },
  data() {
    return {
       ordensProva: ["Aleatória", "Sequencial"],
    }
  }
}

</script>

<style>
  /* .vm{
    max-width: 800px !important;
  } */
</style>